.App {
  text-align: center;
}

.App-logo {
  height: 90px;
  pointer-events: none;
  filter:invert()
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 6s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  0%    { transform: rotate(0); }
  2%    { transform: scale(.8);}
  7%   { transform: scale(1);}
  15%   { transform: rotate(0); }
  30%   { transform: rotate(90deg); }
  32%   { transform: rotate(90deg)scale(.8); }
  37%   { transform: rotate(90deg)scale(1); }
  45%   { transform: rotate(90deg); }
  60%   { transform: rotate(270deg); }
  62%   { transform: rotate(270deg)scale(.8); }
  67%   { transform: rotate(270deg)scale(1); }
  85%   { transform: rotate(270deg); }

}
